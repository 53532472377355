import React, { useEffect, useState } from "react";
import { Col, Row, Container, Form, Button, Card } from "react-bootstrap";
import { HiArrowLeft } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { GrSync } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "../customPlugin/react-confirm-alert";
import "../customPlugin/react-confirm-alert/src/react-confirm-alert.css";
import {
  ref,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
  push,
  update,
} from "firebase/database";
import {
  Header,
  PhaseTable,
  TablePagination,
  Footer,
  AddNewLot,
  PhaseFilter,
  DeleteConfirmModal,
  SyncLoader,
  Spinner,
} from "../components";
import { getLocalText } from "../locales/i18nextInit";
import { rdb, tables } from "../services/config";
import {
  headersKey,
  PIPELINE_LIST,
  MAP_DATA,
  GET_RECENT_CONTACT,
  GET_NOTES_ENGAGEMENT,
  GET_COMPANIES,
} from "../services/hubspotConfig";

const dealsRef = ref(rdb, tables.deals);
const contactRef = ref(rdb, tables.contacts);
const companiesRef = ref(rdb, tables.companies);
const projectRef = ref(rdb, tables.projects);

var limit = 20;
function Phase(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [dealsData, setDealsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadSync, setLoadSync] = useState(false);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  // Modal states
  const [dealName, setDealName] = useState("");
  const [fraisDInfrastructure, setFraisDInfrastructure] = useState("");
  const [identificationDuLotPh, setIdentificationDuLotPh] = useState("");
  const [nombreDePi2, setNombreDePi2] = useState("");
  const [prixAuPi2, setPrixAuPi2] = useState("");
  const [tarifFixePourPh4, setTarifFixePourPh4] = useState("");
  const [statutDesPromessesDAchat, setStatutDesPromessesDAchat] =
    useState("DEFAULT");
  const [amount, setAmount] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [nPages, setNPages] = useState(0);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const starCountRef = query(
        dealsRef,
        orderByChild("pipelineId"),
        equalTo(location?.state?.item[1]?.pipelineId)
      );
      onValue(
        starCountRef,
        (snapshot) => {
          const newDealsData = snapshot.val() || {};
          const currentDeals = Object.entries(newDealsData) || [];
          setIsSearch(false);
          setSearchValue("");
          setLoading(false);
          setDealsData(currentDeals);
          let last = currentPage * limit,
            first = last - limit;
          setCurrentRecords(currentDeals.slice(first, last));
          setNPages(Math.ceil(currentDeals.length / limit)); // last page
        }
        // { onlyOnce: true }
      );
    }
    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setIsSearch(false);
    }
    if (isSearch) {
      searchResultHandle();
    }
  };

  const searchResultHandle = () => {
    const updateDealData = dealsData.filter((item) =>
      item[1]?.dealname_lower
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase())
    );
    setSearchData(updateDealData);
    setCurrentRecords(updateDealData.slice(0, limit));
    setNPages(Math.ceil(updateDealData.length / limit)); // last page
    setCurrentPage(1);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    searchResultHandle();
    setIsSearch(searchValue ? true : false);
  };

  const clearSearch = () => {
    setIsSearch(false);
    setSearchData([]);
    setSearchValue("");
    setCurrentRecords(dealsData.slice(0, limit));
    setNPages(Math.ceil(dealsData.length / limit)); // last page
    setCurrentPage(1);
  };

  const handleModalInput = (e, type) => {
    type(e.target.value);
  };

  const showAddModal = () => setIsShowAddModal(true);

  const closeAddModal = () => {
    setEditItem(null);
    setIsShowAddModal(false);
    handleClearInputs();
  };

  const handleClearInputs = () => {
    setDealName("");
    setFraisDInfrastructure("");
    setIdentificationDuLotPh("");
    setNombreDePi2("");
    setPrixAuPi2("");
    setStatutDesPromessesDAchat("DEFAULT");
    setAmount("");
  };

  const handleDelete = (item, index, key) => {
    setIsDeleteConfirm(true);
    setEditItem({ item, index, key });
  };

  const handleConfirmDelete = () => {
    setIsDeleteConfirm(false);
    set(ref(rdb, `${tables.deals}${editItem.item[0]}`), null);
    setEditItem(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteConfirm(false);
    setEditItem(null);
  };

  const handleEdit = (item, index, key) => {
    const { properties } = item[1];
    setEditItem({ item, index, key });
    setDealName(properties.dealname?.value);
    setFraisDInfrastructure(properties.frais_d_infrastructure?.value);
    setIdentificationDuLotPh(properties.identification_du_lot_ph?.value);
    setNombreDePi2(properties.nombre_de_pi2?.value);
    setPrixAuPi2(properties.prix_au_pi2?.value);
    setTarifFixePourPh4(properties?.tarif_fixe_pour_ph4?.value || "");
    setStatutDesPromessesDAchat(properties.statut_des_promesses_d_achat?.value);
    setAmount(properties.amount?.value);
    showAddModal(true);
  };

  const firebaseToHubspotSync = (pipelineId) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <Card style={{ maxWidth: "450px" }}>
            <Card.Header>
              <h4>Êtes-vous sûr?</h4>
            </Card.Header>
            <Card.Body>
              <p className="mb-2">{getLocalText("Phase.syncing")}</p>
              <p className="font-bold mt-4">
                {getLocalText("Phase.browserWarning")}
              </p>
            </Card.Body>
            <Card.Footer>
              <div className="hstack gap-4">
                <Button
                  variant="green"
                  onClick={() => {
                    onClose();
                    setLoadSync(true);
                    // contactsSync();
                    hubspotToFirebaseSync();
                  }}
                >
                  {getLocalText("Home.confirm")}
                </Button>
                <Button
                  variant="green"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {getLocalText("Home.cancel")}
                </Button>
              </div>
            </Card.Footer>
          </Card>
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
    };
    confirmAlert(options);
  };

  const hubspotToFirebaseSync = () => {
    fetch(PIPELINE_LIST, { headers: headersKey })
      .then((res) => res.json())
      .then(
        (res) => {
          getAllDealsData();
          res.results.forEach((element, index) => {
            const queryData = query(
              projectRef,
              orderByChild("pipelineId"),
              equalTo(element.pipelineId)
            );
            onValue(
              queryData,
              (snapshot) => {
                const val = snapshot.val() || {};
                const data = Object.entries(val) || [];
                var stages = element.stages.map((d) => {
                  return { label: d.label, stageId: d.stageId };
                });
                if (snapshot.exists()) {
                  // update
                  let obj = {
                    ...data[0][1],
                    label: element.label,
                    label_lower: element.label?.toLowerCase() || "",
                    updatedAt: element.updatedAt,
                    pipelineIdHubspot: element.pipelineId || "",
                    stages: stages,
                    createdAt: element.createdAt || "",
                  };
                  let updates = {};
                  updates[tables.projects + data[0][0]] = obj;
                  update(ref(rdb), updates);
                } else {
                  // add
                  let obj = {
                    label: element.label || "",
                    label_lower: element.label?.toLowerCase() || "",
                    createdAt: element.createdAt || "",
                    // displayOrder: item.displayOrder,
                    // objectType: item.objectType,
                    pipelineId: element.pipelineId || "",
                    pipelineIdHubspot: element.pipelineId || "",
                    updatedAt: element.updatedAt || "",
                    status: "Active",
                    stages: stages,
                    // objectTypeId: element.objectTypeId,
                  };
                  var newPipelineRef = push(projectRef);
                  set(newPipelineRef, obj);
                }
              },
              { onlyOnce: true }
            );
          });
        },
        (error) => {}
      );
  };

  const getAllDealsData = () => {
    const recur = async (offset = 0) => {
      await fetch(`${MAP_DATA}&offset=${offset}`, { headers: headersKey })
        .then((res) => res.json())
        .then(
          async (result) => {
            result.deals.forEach(async (element, index) => {
              const queryData = query(
                dealsRef,
                orderByChild("dealId"),
                equalTo(element.dealId)
              );

              onValue(
                queryData,
                (snapshot) => {
                  const val = snapshot.val() || {};
                  const data = Object.entries(val) || [];
                  const { properties } = element;
                  const firebaseObjProperties =
                    data.length > 0 && data[0][1]?.properties;

                  const timeNow = new Date().getTime();
                  const propsVal = {
                    amount: {
                      value: properties?.amount?.value || "",
                      // (firebaseObjProperties?.amount?.timestamp >
                      // properties?.amount?.timestamp
                      //   ? firebaseObjProperties?.amount?.value
                      //   : properties?.amount?.value) || "",
                      timestamp: timeNow,
                      // properties?.amount?.timestamp || timeNow,
                    },
                    dealname: {
                      value: properties?.dealname?.value || "",
                      // (firebaseObjProperties?.dealname?.timestamp >
                      // properties?.dealname?.timestamp
                      //   ? firebaseObjProperties?.dealname?.value
                      //   : properties?.dealname?.value) || "",
                      timestamp: timeNow,
                    },
                    dealstage: {
                      value: properties?.dealstage?.value || "",
                      // (firebaseObjProperties?.dealstage?.timestamp >
                      // properties?.dealstage?.timestamp
                      //   ? firebaseObjProperties?.dealstage?.value
                      //   : properties?.dealstage?.value) || "",
                      timestamp: timeNow,
                    },
                    reservation_via_la_carte_interactive: {
                      value:
                        properties?.reservation_via_la_carte_interactive
                          ?.value || "",
                      timestamp: timeNow,
                    },
                    frais_d_infrastructure: {
                      value: properties?.frais_d_infrastructure?.value || "",
                      // (firebaseObjProperties?.frais_d_infrastructure
                      //   ?.timestamp >
                      // properties?.frais_d_infrastructure?.timestamp
                      //   ? firebaseObjProperties?.frais_d_infrastructure?.value
                      //   : properties?.frais_d_infrastructure?.value) || "",
                      timestamp: timeNow,
                    },
                    depot_requis: {
                      value: properties?.depot_requis?.value || "",
                      timestamp: timeNow,
                    },
                    identification_du_lot_ph: {
                      value: properties?.identification_du_lot_ph?.value || "",
                      // (firebaseObjProperties?.identification_du_lot_ph
                      //   ?.timestamp >
                      // properties?.identification_du_lot_ph?.timestamp
                      //   ? firebaseObjProperties?.identification_du_lot_ph
                      //       ?.value
                      //   : properties?.identification_du_lot_ph?.value) || "",
                      timestamp: timeNow,
                    },
                    nombre_de_pi2: {
                      value: properties?.nombre_de_pi2?.value || "",
                      // (firebaseObjProperties?.nombre_de_pi2?.timestamp >
                      // properties?.nombre_de_pi2?.timestamp
                      //   ? firebaseObjProperties?.nombre_de_pi2?.value
                      //   : properties?.nombre_de_pi2?.value) || "",
                      timestamp: timeNow,
                    },
                    pipeline: {
                      value: properties?.pipeline?.value || "",
                      // (firebaseObjProperties?.pipeline?.timestamp >
                      // properties?.pipeline?.timestamp
                      //   ? firebaseObjProperties?.pipeline?.value
                      //   : properties?.pipeline?.value) || "",
                      timestamp: timeNow,
                    },
                    prix_au_pi2: {
                      value: properties?.prix_au_pi2?.value || "",
                      // (firebaseObjProperties?.prix_au_pi2?.timestamp >
                      // properties?.prix_au_pi2?.timestamp
                      //   ? firebaseObjProperties?.prix_au_pi2?.value
                      //   : properties?.prix_au_pi2?.value) || "",
                      timestamp: timeNow,
                    },
                    statut_des_promesses_d_achat: {
                      value:
                        properties?.statut_des_promesses_d_achat?.value || "",
                      // (firebaseObjProperties?.statut_des_promesses_d_achat
                      //   ?.timestamp >
                      // properties?.statut_des_promesses_d_achat?.timestamp
                      //   ? firebaseObjProperties?.statut_des_promesses_d_achat
                      //       ?.value
                      //   : properties?.statut_des_promesses_d_achat?.value) ||
                      // "",
                      timestamp: timeNow,
                    },
                    depot_necessaire: {
                      value: properties?.depot_necessaire?.value || "",
                      // (firebaseObjProperties?.depot_necessaire?.timestamp >
                      // properties?.depot_necessaire?.timestamp
                      //   ? firebaseObjProperties?.depot_necessaire?.value
                      //   : properties?.depot_necessaire?.value) || "",
                      timestamp: timeNow,
                    },
                    description: {
                      value: properties?.description?.value || "",
                      // (firebaseObjProperties?.description?.timestamp >
                      // properties?.description?.timestamp
                      //   ? firebaseObjProperties?.description?.value
                      //   : properties?.description?.value) || "",
                      timestamp: timeNow,
                    },
                    prix_total_sans_infrastructure: {
                      value:
                        properties?.prix_total_sans_infrastructure?.value || "",
                      // (firebaseObjProperties?.prix_total_sans_infrastructure
                      //   ?.timestamp >
                      // properties?.prix_total_sans_infrastructure?.timestamp
                      //   ? firebaseObjProperties
                      //       ?.prix_total_sans_infrastructure?.value
                      //   : properties?.prix_total_sans_infrastructure
                      //       ?.value) || "",
                      timestamp: timeNow,
                    },
                    tarif_fixe_pour_ph4: {
                      value: properties?.tarif_fixe_pour_ph4?.value || "",
                      timestamp: timeNow,
                    },
                  };
                  if (snapshot.exists()) {
                    // update
                    let firebaseAssociations = data[0][1]?.associations,
                      hubspotAssociations = element?.associations;
                    let firebaseCompanyIds =
                        firebaseAssociations?.associatedCompanyIds || [],
                      hubspotCompanyIds =
                        hubspotAssociations?.associatedCompanyIds || [],
                      firebaseContactIds =
                        firebaseAssociations?.associatedVids || [],
                      hubspotContactIds =
                        hubspotAssociations?.associatedVids || [];
                    let obj = {
                      ...data[0][1],
                      // dealId: element.dealId,
                      dealIdHubspot: element.dealId,
                      portalId: element.portalId,
                      pipelineId: propsVal.pipeline.value,
                      dealname_lower:
                        properties?.dealname?.value?.toLowerCase() || "",
                      properties: propsVal,
                      associations: {
                        associatedCompanyIds: hubspotCompanyIds,
                        //  firebaseCompanyIds.concat(
                        //   hubspotCompanyIds.filter(
                        //     (item) => firebaseCompanyIds.indexOf(item) < 0
                        //   )
                        // ),
                        associatedVids: hubspotContactIds,
                        // firebaseContactIds.concat(
                        //   hubspotContactIds.filter(
                        //     (item) => firebaseContactIds.indexOf(item) < 0
                        //   )
                        // ),
                        associatedDealIds:
                          hubspotAssociations.associatedDealIds,
                        associatedTicketIds:
                          hubspotAssociations.associatedTicketIds,
                      },
                    };

                    let updates = {};
                    updates[tables.deals + data[0][0]] = obj;
                    update(ref(rdb), updates);
                  } else {
                    // add
                    let obj = {
                      dealId: element.dealId,
                      dealIdHubspot: element.dealId,
                      portalId: element.portalId,
                      pipelineId: properties?.pipeline?.value || "",
                      properties: propsVal,
                      associations: element.associations,
                    };
                    var newDealRef = push(dealsRef);
                    set(newDealRef, obj);
                  }
                  if (result.hasMore && result.deals.length - 1 === index) {
                    // setTimeout(() => {
                    recur(result.offset);
                    // }, 10000);
                  } else if (
                    !result.hasMore &&
                    result.deals.length - 1 === index
                  ) {
                    handleContactData();
                    // setTimeout(() => {
                    //   callSyncDealsNotesData();
                    // }, 1000);
                  }
                },
                { onlyOnce: true }
              );
            });
            // result.deals.forEach(async (element, index) => {
            //   const queryData = query(
            //     dealsRef,
            //     orderByChild("dealId"),
            //     equalTo(element.dealId)
            //   );
            //   var engagementData = [];
            //   const recurEngage = async (offset = 0) => {
            //     fetch(`${GET_ENGAGEMENT(element.dealId)}&offset=${offset}`, {
            //       headers: headersKey,
            //     })
            //       .then((res) => res.json())
            //       .then((res) => {
            //         engagementData = res.results
            //           ? [...engagementData, ...res.results]
            //           : engagementData;

            //         if (res.hasMore) {
            //           recurEngage(res.offset);
            //         } else {
            //           var notesData = engagementData
            //             .filter((d) => d.engagement.type === "NOTE")
            //             .map((d) => {
            //               return {
            //                 title: d.engagement.bodyPreview || "",
            //                 hubspotId: d.engagement.id || "",
            //               };
            //             });

            //           onValue(
            //             queryData,
            //             (snapshot) => {
            //               const val = snapshot.val() || {};
            //               const data = Object.entries(val) || [];
            //               let firebaseNotes =
            //                 data.length > 0 && data[0][1]?.notes?.length > 0
            //                   ? data[0][1].notes.map((d) => d.hubspotId)
            //                   : [];

            //               notesData = notesData.filter(
            //                 (d) => !firebaseNotes.includes(d.hubspotId)
            //               );
            //               const { properties } = element;
            //               const firebaseObjProperties =
            //                 data.length > 0 && data[0][1]?.properties;

            //               const timeNow = new Date().getTime();
            //               const propsVal = {
            //                 amount: {
            //                   value:
            //                     (firebaseObjProperties?.amount?.timestamp >
            //                     properties?.amount?.timestamp
            //                       ? firebaseObjProperties?.amount?.value
            //                       : properties?.amount?.value) || "",
            //                   timestamp: timeNow,
            //                   // properties?.amount?.timestamp || timeNow,
            //                 },
            //                 dealname: {
            //                   value:
            //                     (firebaseObjProperties?.dealname?.timestamp >
            //                     properties?.dealname?.timestamp
            //                       ? firebaseObjProperties?.dealname?.value
            //                       : properties?.dealname?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 dealstage: {
            //                   value:
            //                     (firebaseObjProperties?.dealstage?.timestamp >
            //                     properties?.dealstage?.timestamp
            //                       ? firebaseObjProperties?.dealstage?.value
            //                       : properties?.dealstage?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 frais_d_infrastructure: {
            //                   value:
            //                     (firebaseObjProperties?.frais_d_infrastructure
            //                       ?.timestamp >
            //                     properties?.frais_d_infrastructure?.timestamp
            //                       ? firebaseObjProperties
            //                           ?.frais_d_infrastructure?.value
            //                       : properties?.frais_d_infrastructure
            //                           ?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 identification_du_lot_ph: {
            //                   value:
            //                     (firebaseObjProperties?.identification_du_lot_ph
            //                       ?.timestamp >
            //                     properties?.identification_du_lot_ph?.timestamp
            //                       ? firebaseObjProperties
            //                           ?.identification_du_lot_ph?.value
            //                       : properties?.identification_du_lot_ph
            //                           ?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 nombre_de_pi2: {
            //                   value:
            //                     (firebaseObjProperties?.nombre_de_pi2
            //                       ?.timestamp >
            //                     properties?.nombre_de_pi2?.timestamp
            //                       ? firebaseObjProperties?.nombre_de_pi2?.value
            //                       : properties?.nombre_de_pi2?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 pipeline: {
            //                   value:
            //                     (firebaseObjProperties?.pipeline?.timestamp >
            //                     properties?.pipeline?.timestamp
            //                       ? firebaseObjProperties?.pipeline?.value
            //                       : properties?.pipeline?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 prix_au_pi2: {
            //                   value:
            //                     (firebaseObjProperties?.prix_au_pi2?.timestamp >
            //                     properties?.prix_au_pi2?.timestamp
            //                       ? firebaseObjProperties?.prix_au_pi2?.value
            //                       : properties?.prix_au_pi2?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 statut_des_promesses_d_achat: {
            //                   value:
            //                     (firebaseObjProperties
            //                       ?.statut_des_promesses_d_achat?.timestamp >
            //                     properties?.statut_des_promesses_d_achat
            //                       ?.timestamp
            //                       ? firebaseObjProperties
            //                           ?.statut_des_promesses_d_achat?.value
            //                       : properties?.statut_des_promesses_d_achat
            //                           ?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 depot_necessaire: {
            //                   value:
            //                     (firebaseObjProperties?.depot_necessaire
            //                       ?.timestamp >
            //                     properties?.depot_necessaire?.timestamp
            //                       ? firebaseObjProperties?.depot_necessaire
            //                           ?.value
            //                       : properties?.depot_necessaire?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //                 description: {
            //                   value:
            //                     (firebaseObjProperties?.description?.timestamp >
            //                     properties?.description?.timestamp
            //                       ? firebaseObjProperties?.description?.value
            //                       : properties?.description?.value) || "",
            //                   timestamp: timeNow,
            //                 },
            //               };
            //               if (snapshot.exists()) {
            //                 // update
            //                 let firebaseAssociations = data[0][1]?.associations,
            //                   hubspotAssociations = element?.associations;
            //                 let firebaseCompanyIds =
            //                     firebaseAssociations?.associatedCompanyIds ||
            //                     [],
            //                   hubspotCompanyIds =
            //                     hubspotAssociations?.associatedCompanyIds || [],
            //                   firebaseContactIds =
            //                     firebaseAssociations?.associatedVids || [],
            //                   hubspotContactIds =
            //                     hubspotAssociations?.associatedVids || [];
            //                 let obj = {
            //                   ...data[0][1],
            //                   notes:
            //                     (data[0][1]?.notes &&
            //                     data[0][1]?.notes.length > 0
            //                       ? [...data[0][1]?.notes, ...notesData]
            //                       : notesData) || [],
            //                   // dealId: element.dealId,
            //                   dealIdHubspot: element.dealId,
            //                   portalId: element.portalId,
            //                   pipelineId: propsVal.pipeline.value,
            //                   dealname_lower:
            //                     properties?.dealname?.value?.toLowerCase() ||
            //                     "",
            //                   properties: propsVal,
            //                   associations: {
            //                     associatedCompanyIds: firebaseCompanyIds.concat(
            //                       hubspotCompanyIds.filter(
            //                         (item) =>
            //                           firebaseCompanyIds.indexOf(item) < 0
            //                       )
            //                     ),
            //                     associatedVids: firebaseContactIds.concat(
            //                       hubspotContactIds.filter(
            //                         (item) =>
            //                           firebaseContactIds.indexOf(item) < 0
            //                       )
            //                     ),
            //                     associatedDealIds:
            //                       hubspotAssociations.associatedDealIds,
            //                     associatedTicketIds:
            //                       hubspotAssociations.associatedTicketIds,
            //                   },
            //                 };

            //                 let updates = {};
            //                 updates[tables.deals + data[0][0]] = obj;
            //                 update(ref(rdb), updates);
            //               } else {
            //                 // add
            //                 let obj = {
            //                   dealId: element.dealId,
            //                   dealIdHubspot: element.dealId,
            //                   portalId: element.portalId,
            //                   pipelineId: properties?.pipeline?.value || "",
            //                   properties: propsVal,
            //                   associations: element.associations,
            //                   notes:
            //                     (data.length > 0 &&
            //                     data[0][1]?.notes &&
            //                     data[0][1]?.notes.length > 0
            //                       ? [...data[0][1]?.notes, ...notesData]
            //                       : notesData) || [],
            //                 };
            //                 var newDealRef = push(dealsRef);
            //                 set(newDealRef, obj);
            //               }
            //             },
            //             { onlyOnce: true }
            //           );
            //         }
            //       });
            //   };
            //   recurEngage(0);
            //   if (result.hasMore && result.deals.length - 1 === index) {
            //     setTimeout(() => {
            //       recur(result.offset);
            //     }, 10000);
            //   } else if (!result.hasMore && result.deals.length - 1 === index) {
            //     handleContactData();
            //   }
            // });
          },
          (error) => {}
        );
    };
    return recur(0);
  };

  const callSyncDealsNotesData = (result) => {
    const recurEngage = async (offset = 0) => {
      fetch(`${GET_NOTES_ENGAGEMENT}&after=${offset}`, {
        headers: headersKey,
      })
        .then((res) => res.json())
        .then((res) => {
          var counter = 0;
          res.results.forEach((ele) => {
            if (ele?.associations?.deals?.results[0]?.id) {
              const queryData = query(
                dealsRef,
                orderByChild("dealId"),
                equalTo(Number(ele?.associations?.deals?.results[0]?.id || ""))
              );
              onValue(
                queryData,
                (snapshot) => {
                  const val = snapshot.val() || {};
                  const data = Object.entries(val) || [];
                  const keyValue = data[0][0];
                  const values = data[0][1];
                  var notes = values?.notes
                    ? Object.entries(values?.notes)
                    : [];
                  var notesIndex =
                    notes.length > 0 &&
                    notes.findIndex((e) => e[1].hubspotId === Number(ele?.id));
                  if (notesIndex === -1) {
                    var newNotes = notes.map((d) => d[1]);
                    var addNotes =
                      (newNotes && newNotes.length > 0
                        ? [
                            ...newNotes,
                            {
                              title: ele?.properties?.hs_note_body || "",
                              hubspotId: ele?.id || "",
                            },
                          ]
                        : values?.notes) || [];
                    let obj = {
                      ...values,
                      notes: addNotes,
                    };
                    let updates = {};
                    updates[tables.deals + keyValue] = obj;
                    update(ref(rdb), updates);
                    counter = counter + 1;
                    if (counter === res.results.length) {
                      if (res?.paging?.next) {
                        recurEngage(res.paging.next.after);
                      } else {
                        handleContactData();
                      }
                    }
                  } else {
                    counter = counter + 1;
                    if (counter === res.results.length) {
                      if (res?.paging?.next) {
                        recurEngage(res.paging.next.after);
                      } else {
                        handleContactData();
                      }
                    }
                  }
                },
                { onlyOnce: true }
              );
            } else {
              counter = counter + 1;

              if (counter === res.results.length) {
                if (res?.paging?.next) {
                  recurEngage(res.paging.next.after);
                } else {
                  handleContactData();
                }
              }
            }
          });
        });
    };
    recurEngage(0);
  };

  const handleContactData = () => {
    const recur = (vidOffset = 0) => {
      fetch(`${GET_RECENT_CONTACT}&vidOffset=${vidOffset}`, {
        headers: headersKey,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            result?.contacts?.forEach((element, index) => {
              const queryData = query(
                contactRef,
                orderByChild("hubspotVid"),
                equalTo(element.vid)
              );
              onValue(
                queryData,
                (snapshot) => {
                  const val = snapshot.val() || {};
                  const data = Object.entries(val) || [];
                  if (snapshot.exists()) {
                    // update
                    let updateFirebaseObj =
                      element?.properties?.lastmodifieddate?.value >
                        data[0][1]?.lastmodifieddate ||
                      !data[0][1]?.lastmodifieddate
                        ? {
                            name: element?.properties?.firstname?.value || "",
                            lastname:
                              element?.properties?.lastname?.value || "",
                            name_lower:
                              (element?.properties?.firstname?.value?.toLowerCase() ||
                                "") +
                              (element?.properties?.firstname?.value &&
                              element?.properties?.lastname?.value
                                ? " "
                                : "") +
                              (element?.properties?.lastname?.value?.toLowerCase() ||
                                ""),
                            phoneNumber:
                              element?.properties?.phone?.value || "",
                            lastmodifieddate: Number(
                              element?.properties?.lastmodifieddate?.value
                            ),
                          }
                        : {
                            lastmodifieddate: data[0][1]?.lastmodifieddate,
                          };

                    let obj = {
                      ...data[0][1],
                      ...updateFirebaseObj,
                      hubspotVid: element?.vid || "",
                      email:
                        element["identity-profiles"][0]?.identities?.filter(
                          (d) => d.type === "EMAIL"
                        )[0]?.value || "",
                      // element["identity-profiles"][0].identities[0]?.type ===
                      // "EMAIL"
                      //   ? element["identity-profiles"][0].identities[0]?.value
                      //   : "",
                      // category: "Normal",
                      // createdDate: element?.addedAt || "",
                      // vid: element.vid,
                      // type: "personal",
                    };
                    let updates = {};
                    updates[tables.contacts + data[0][0]] = obj;
                    update(ref(rdb), updates);
                  } else {
                    // add
                    let obj = {
                      name: element?.properties?.firstname?.value || "",
                      lastname: element?.properties?.lastname?.value || "",
                      name_lower:
                        element?.properties?.firstname?.value?.toLowerCase() ||
                        "",
                      email:
                        element["identity-profiles"][0].identities.filter(
                          (d) => d.type === "EMAIL"
                        )[0]?.value || "",
                      //email: element["identity-profiles"][0].identities[0]?.type ===
                      // "EMAIL"
                      //   ? element["identity-profiles"][0].identities[0]?.value
                      //   : "",
                      phoneNumber: element?.properties?.phone?.value || "",
                      category: "Normal",
                      createdDate: element?.addedAt || "",
                      vid: element.vid || "",
                      hubspotVid: element.vid || "",
                      type: "personal",
                      address: element?.properties?.address?.value || "",
                      lastmodifieddate: Number(
                        element?.properties?.lastmodifieddate?.value
                      ),
                    };
                    var newContactRef = push(contactRef);
                    set(newContactRef, obj);
                  }

                  if (
                    result["has-more"] &&
                    result?.contacts?.length - 1 === index
                  ) {
                    setTimeout(() => {
                      recur(result["vid-offset"]);
                    }, 1000);
                  } else if (
                    !result["has-more"] &&
                    result?.contacts?.length - 1 === index
                  ) {
                    handleCompanyData();
                  }
                },
                { onlyOnce: true }
              );
            });
          },
          (error) => {}
        );
    };
    return recur(0);
  };

  const handleCompanyData = () => {
    setLoadSync(true);
    const recur = (vidOffset = 0) => {
      fetch(`${GET_COMPANIES}&after=${vidOffset}`, {
        headers: headersKey,
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            var counter = 0;
            result?.results?.forEach((element, index) => {
              const queryData = query(
                companiesRef,
                orderByChild("companyId"),
                equalTo(Number(element.id))
              );
              onValue(
                queryData,
                (snapshot) => {
                  const val = snapshot.val() || {};
                  const data = Object.entries(val) || [];
                  const keyValue = data.length > 0 ? data[0][0] : "",
                    values = data.length > 0 && data[0][1];
                  let objCommon = {
                    name: element?.properties?.name || "",
                    name_lower: element?.properties?.name?.toLowerCase() || "",
                    website: element?.properties?.website || "",
                    phone: element?.properties?.phone || "",
                    description: element?.properties?.description || "",
                  };
                  if (snapshot.exists()) {
                    let firebaseContactVids = values?.contactVids || [];
                    var hubspotContacts =
                      element?.associations?.contacts?.results.map((d) =>
                        Number(d.id)
                      ) || [];

                    var contacts =
                      firebaseContactVids.concat(
                        hubspotContacts.filter(
                          (item) => firebaseContactVids.indexOf(item) < 0
                        )
                      ) || [];

                    let obj = {
                      ...values,
                      ...objCommon,
                      hubspotCompanyIds: Number(element.id),
                      // companyId: element.companyId,
                      contactVids: contacts,
                    };
                    let updates = {};
                    updates[tables.companies + keyValue] = obj;
                    update(ref(rdb), updates);
                    counter = counter + 1;
                  } else {
                    // add
                    let obj = {
                      ...objCommon,
                      category: "Normal",
                      companyId: Number(element.id),
                      type: "company",
                      contactVids: [...new Set(hubspotContacts)] || [],
                      hubspotCompanyIds: Number(element.id),
                    };
                    var newCompaniesRef = push(companiesRef);
                    set(newCompaniesRef, obj);
                    counter = counter + 1;
                  }
                  if (counter === result?.results.length) {
                    if (result?.paging?.next) {
                      recur(result.paging.next.after);
                    } else {
                      setLoadSync(false);
                    }
                  }
                },
                { onlyOnce: true }
              );
            });

            // result?.results?.forEach((element, index) => {
            //   const queryData = query(
            //     companiesRef,
            //     orderByChild("companyId"),
            //     equalTo(element.companyId)
            //   );
            //   onValue(
            //     queryData,
            //     async (snapshot) => {
            //       // // Get all contacts of company
            //       // await fetch(GET_CONTACT_OF_COMPANY(element.companyId), {
            //       //   headers: headersKey,
            //       // })
            //       //   .then((res) => res.json())
            //       //   .then((res) => {
            //       //     const val = snapshot.val() || {};
            //       //     const data = Object.entries(val) || [];
            //       //     let objCommon = {
            //       //       name: element?.properties?.name?.value || "",
            //       //       name_lower:
            //       //         element?.properties?.name?.value?.toLowerCase() || "",
            //       //       website: element.website || "",
            //       //       phone: element?.phone || "",
            //       //       description: element?.description || "",
            //       //     };
            //       //     if (snapshot.exists()) {
            //       //       // update
            //       //       let firebaseContactVids = data[0][1]?.contactVids || [],
            //       //         hubspotContactVids = res?.vids || [];

            //       //       let obj = {
            //       //         ...data[0][1],
            //       //         ...objCommon,
            //       //         hubspotCompanyIds: element.companyId,
            //       //         // companyId: element.companyId,
            //       //         contactVids:
            //       //           firebaseContactVids.concat(
            //       //             hubspotContactVids.filter(
            //       //               (item) => firebaseContactVids.indexOf(item) < 0
            //       //             )
            //       //           ) || [],
            //       //       };
            //       //       let updates = {};
            //       //       updates[tables.companies + data[0][0]] = obj;
            //       //       update(ref(rdb), updates);
            //       //     } else {
            //       //       // add
            //       //       let obj = {
            //       //         ...objCommon,
            //       //         category: "Normal",
            //       //         companyId: element.companyId,
            //       //         type: "company",
            //       //         contactVids: res?.vids || [],
            //       //         hubspotCompanyIds: element.companyId,
            //       //       };
            //       //       var newCompaniesRef = push(companiesRef);
            //       //       set(newCompaniesRef, obj);
            //       //     }
            //       //   });
            //     },
            //     { onlyOnce: true }
            //   );
            //   if (result.hasMore && result?.results.length - 1 === index) {
            //     setTimeout(() => {
            //       recur(result.offset);
            //     }, 10000);
            //   } else if (
            //     !result.hasMore &&
            //     result?.results.length - 1 === index
            //   ) {
            //     setLoadSync(false);
            //   }
            // });
          },
          (error) => {
            setLoadSync(false);
          }
        );
    };
    return recur(0);
  };

  const handleNext = () => {
    if (currentPage !== nPages) {
      let data = isSearch ? searchData : dealsData;
      let last = (currentPage + 1) * limit,
        first = last - limit;
      setCurrentRecords(data.slice(first, last));
      setNPages(Math.ceil(data.length / limit)); // last page
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      let data = isSearch ? searchData : dealsData;
      let last = (currentPage - 1) * limit,
        first = last - limit;
      setCurrentRecords(data.slice(first, last));
      setNPages(Math.ceil(data.length / limit)); // last page
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <section className="vstack min-h-screen">
      <Header />
      {loading ? <SyncLoader /> : null}
      {loadSync ? <Spinner loading={loadSync} /> : null}
      <section className="bg-darkblue py-10">
        <Container
          fluid={window.innerWidth > 1600 && true}
          className={window.innerWidth > 1600 ? "px-16" : ""}
        >
          <Row className="gy-5">
            <Col lg={6} md={4}>
              <h1 className="text-white">
                <a onClick={() => navigate("/home")} href={() => false}>
                  <HiArrowLeft className="me-3 text-white" />
                </a>
                Pine Hill - {location?.state?.item[1]?.label}
              </h1>
            </Col>
            <Col lg={4} md={8} className="ms-auto">
              <Form>
                <Form.Group className="mb-0 d-flex">
                  <Form.Control
                    type="text"
                    placeholder={getLocalText("Home.search")}
                    value={searchValue}
                    onChange={(e) => handleSearch(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchButton(e);
                      }
                    }}
                  />
                  <Button
                    variant="outline-white"
                    className="ms-2"
                    onClick={handleSearchButton}
                  >
                    {getLocalText("Home.search")}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="vstack">
        <Container
          fluid={window.innerWidth > 1600 && true}
          className={window.innerWidth > 1600 ? "px-16" : ""}
        >
          <PhaseFilter
            dealsDataLength={dealsData.length}
            projectDetails={location?.state?.item[1]}
          />
          <Row className="py-5">
            <Col xs="auto">
              <div className="hstack gap-3 flex-wrap h-full">
                <p className="font-bold">
                  {isSearch
                    ? getLocalText("Home.searchResult")
                    : `${getLocalText("Phase.allLots")}(${
                        isSearch ? searchData.length : dealsData.length
                      })`}
                </p>
                {isSearch ? (
                  <span className="badge badge-lg border rounded-pill text-pink border-light-white">
                    {searchValue}
                    <Button
                      variant="link"
                      className="bg-transparent p-0 border-0  shadow-soft-none ms-1 lh-none"
                      onClick={clearSearch}
                    >
                      <IoClose className="text-pink text-lg" />
                    </Button>
                  </span>
                ) : null}
              </div>
            </Col>
            <Col
              xs="auto"
              className="hstack justify-content-end gap-3 flex-wrap ms-auto"
            >
              <Button
                variant="outline-green"
                className="align-items-center"
                onClick={() =>
                  firebaseToHubspotSync(location?.state?.item[1]?.pipelineId)
                }
              >
                <GrSync className="me-2" />
                {getLocalText("Home.smartSync")}
              </Button>
              <AddNewLot
                pipelineItem={location?.state?.item[1]}
                dealName={dealName}
                fraisDInfrastructure={fraisDInfrastructure}
                identificationDuLotPh={identificationDuLotPh}
                nombreDePi2={nombreDePi2}
                prixAuPi2={prixAuPi2}
                tarifFixePourPh4={tarifFixePourPh4}
                statutDesPromessesDAchat={statutDesPromessesDAchat}
                amount={amount}
                setDealName={setDealName}
                setFraisDInfrastructure={setFraisDInfrastructure}
                setIdentificationDuLotPh={setIdentificationDuLotPh}
                setNombreDePi2={setNombreDePi2}
                setPrixAuPi2={setPrixAuPi2}
                setTarifFixePourPh4={setTarifFixePourPh4}
                setStatutDesPromessesDAchat={setStatutDesPromessesDAchat}
                setAmount={setAmount}
                handleModalInput={handleModalInput}
                show={isShowAddModal}
                handleClose={closeAddModal}
                handleClearInputs={handleClearInputs}
                edit={editItem}
                phase={location?.state?.item[1]?.label}
              />
            </Col>
          </Row>
          <PhaseTable
            dealsData={currentRecords}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            pipelineItem={location?.state?.item[1]}
            phase={location?.state?.item[1]?.label}
          />
          <DeleteConfirmModal
            show={isDeleteConfirm}
            handleClose={handleCloseDeleteModal}
            handleConfirm={handleConfirmDelete}
          />
        </Container>
      </div>
      <TablePagination
        handleNext={handleNext}
        currentPage={currentPage}
        handlePrevious={handlePrevious}
      />
      <Footer />
    </section>
  );
}
export default Phase;
