import { IS_STAGING } from "./config";

const key = IS_STAGING
  ? process.env.REACT_APP_HUBSPOT_STAGING //staging
  : process.env.REACT_APP_HUBSPOT_PRODUCTION; //production

// export const headersKey = IS_STAGING
//   ? {
//       Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_STAGING_TOKEN}`,
//     }
//   : {};

export const headersKey = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${
    IS_STAGING
      ? process.env.REACT_APP_HUBSPOT_STAGING_TOKEN
      : process.env.REACT_APP_HUBSPOT_PRODUCTION_TOKEN
  }`,
};

const proxy = "https://cors.stage.eugeniuses.com/";

export const MAP_DATA = `${proxy}https://api.hubapi.com/deals/v1/deal/paged?properties=statut_des_promesses_d_achat&properties=identification_du_lot_ph&properties=depot_requis&properties=frais_d_infrastructure&properties=prix_total_sans_infrastructure&properties=tarif_fixe_pour_ph4&properties=prix_au_pi2&properties=nombre_de_pi2&properties=depot_necessaire&properties=description&properties=dealname&properties=amount&properties=pipeline&properties=dealstage&properties=reservation_via_la_carte_interactive&limit=250&includeAssociations=true`;

export const PIPELINE_LIST = `${proxy}https://api.hubapi.com/crm-pipelines/v1/pipelines/deals`;

export const GET_ALL_CONTACT = `${proxy}https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=500&property=firstname&property=lastname&property=phone&property=company&property=lastmodifieddate`;

export const GET_RECENT_CONTACT = `${proxy}https://api.hubapi.com/contacts/v1/lists/recently_updated/contacts/recent?count=100&property=firstname&property=lastname&property=phone&property=company&property=lastmodifieddate`;

export const GET_ALL_COMPANY = `${proxy}https://api.hubapi.com/companies/v2/companies/paged?properties=name&properties=website&limit=50`;

export const GET_RECENT_COMPANY = `${proxy}https://api.hubapi.com/companies/v2/companies/recent/modified?properties=name&properties=website&count=100`;

export const GET_COMPANIES = `${proxy}https://api.hubapi.com/crm/v3/objects/companies?limit=100&associations=contacts&properties=name&properties=phone&properties=state&properties=industry&properties=city&properties=description&properties=website`;

export const GET_CONTACT_OF_COMPANY = (id) => {
  return `${proxy}https://api.hubapi.com/companies/v2/companies/${id}/vids`;
};

// Sync to Hubspot
export const UPDATE_PIPELINE = (pipelineId) => {
  return `${proxy}https://api.hubapi.com/crm-pipelines/v1/pipelines/deals/${pipelineId}`;
};

export const UPDATE_DEAL = (dealId) =>
  `${proxy}https://api.hubapi.com/deals/v1/deal/${dealId}`;

export const UPDATE_GROUP_OF_DEALS = `${proxy}https://api.hubapi.com/deals/v1/batch-async/update`;

export const CREATE_CONTACT = `${proxy}https://api.hubapi.com/contacts/v1/contact`;

export const CRM_ASSOCIATIONS_BATCH = `${proxy}https://api.hubapi.com/crm-associations/v1/associations/create-batch`;

export const UPDATE_CONTACT = (contactId) => {
  return `${proxy}https://api.hubapi.com/contacts/v1/contact/vid/${contactId}/profile`;
};

export const CREATE_DEAL = `${proxy}https://api.hubapi.com/deals/v1/deal`;

export const GET_CONTACT_BY_EMAIL = (email) => {
  return `${proxy}https://api.hubapi.com/contacts/v1/contact/email/${email}/profile`;
};

export const UPDATE_GROUP_OF_COMPANIES = `${proxy}https://api.hubapi.com/companies/v1/batch-async/update`;

export const UPDATE_COMPANY_BY_ID = (id) => {
  return `${proxy}https://api.hubapi.com/companies/v2/companies/${id}`;
};

export const ADD_CONTACT_TO_COMPANY = (comapany, contact) => {
  return `${proxy}https://api.hubapi.com/companies/v2/companies/${comapany}/contacts/${contact}`;
};

export const CREATE_COMPANY = `${proxy}https://api.hubapi.com/companies/v2/companies`;

// export const GET_ENGAGEMENT = `${proxy}https://api.hubapi.com/crm-associations/v1/associations/5409956611/HUBSPOT_DEFINED/11?${hapikey}&type=NOTE`;

export const GET_ENGAGEMENT = (dealId) => {
  return `${proxy}https://api.hubapi.com/engagements/v1/engagements/associated/DEAL/${dealId}/paged?limit=100`;
};

export const GET_RECENT_ENGAGEMENT = `${proxy}https://api.hubapi.com/engagements/v1/engagements/recent/modified?count=100&since=1483246800000`;

export const GET_NOTES_ENGAGEMENT = `${proxy}https://api.hubapi.com/crm/v3/objects/notes?limit=100&properties=hs_note_body&archived=false&associations=deals`;

export const CREATE_ENGAGEMENT = `${proxy}https://api.hubapi.com/engagements/v1/engagements`;

export const GET_SINGLE_DEAL = (dealId) => UPDATE_DEAL(dealId);

export const UPDATE_BATCH_CONTACTS = `${proxy}https://api.hubapi.com/contacts/v1/contact/batch/`;
