import React from "react";
import { Table, Form } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";
import { ref, update } from "firebase/database";
import { rdb, tables } from "../services/config";
import { getLocalText } from "../locales/i18nextInit";
import { constant } from "../utils/constant";
import { getStageValue } from "../utils/helper";

function PhaseTable(props) {
  const { pipelineItem, phase } = props;
  const navigate = useNavigate();
  const isPhase4 = phase?.toLowerCase()?.includes("phase 4");
  const headerData = isPhase4
    ? [
        "#",
        getLocalText("Phase.lotId"),
        getLocalText("Phase.fixedRate"),
        getLocalText("Phase.squareFeet"), //nombre_de_pi2
        getLocalText("Phase.pricePerSquareFeet"), //prix_au_pi2
        getLocalText("Phase.infrastructureCost"), // frais_d_infrastructure
        getLocalText("Home.status"),
        "",
      ]
    : [
        "#",
        // getLocalText("Home.name"),
        getLocalText("Phase.lotId"),
        getLocalText("Phase.squareFeet"), //nombre_de_pi2
        getLocalText("Phase.pricePerSquareFeet"), //prix_au_pi2
        getLocalText("Phase.infrastructureCost"), // frais_d_infrastructure
        getLocalText("Home.status"),
        "",
      ];

  // const [status, setStatus] = useState("DEFAULT");
  return (
    <>
      <Table responsive className="mb-5 data-table">
        <thead className="border-top border-light-white">
          <tr>
            {headerData.map((item, index) => (
              <th
                key={index.toString()}
                className="text-base text-blue text-capitalize"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.dealsData
            ? Object.entries(props.dealsData).map(([key, value], index) => {
                console.log("value[1]?.properties", value[1]?.properties);

                return (
                  <tr key={index.toString()}>
                    <td className="font-bold text-base">{index + 1}</td>
                    <td className="text-base text-blue">
                      {value[1]?.properties?.identification_du_lot_ph?.value ||
                        value[1]?.properties?.dealname?.value}
                    </td>
                    {isPhase4 ? (
                      <td className="text-base text-blue">
                        {value[1]?.properties?.tarif_fixe_pour_ph4?.value}
                      </td>
                    ) : null}
                    <td className="text-base text-blue">
                      {value[1]?.properties?.nombre_de_pi2?.value}
                    </td>
                    <td className="text-base text-blue">
                      {value[1]?.properties?.prix_au_pi2?.value}
                    </td>
                    <td className="text-base text-blue">
                      {value[1]?.properties?.frais_d_infrastructure?.value}
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        className="px-2 text-base select-placeholder w-56"
                        // defaultValue={"DEFAULT"}
                        value={
                          value[1]?.properties?.statut_des_promesses_d_achat
                            ?.value
                        }
                        onChange={(e) => {
                          const stage = getStageValue(
                            pipelineItem,
                            e.target.value
                          );
                          const timeNow = new Date().getTime();
                          var obj = {
                            ...value[1],
                            properties: {
                              ...value[1].properties,
                              statut_des_promesses_d_achat: {
                                value: e.target.value,
                                timestamp: timeNow,
                              },
                              dealstage: {
                                value: stage || "",
                                timestamp: timeNow,
                              },
                            },
                          };
                          var updates = {};
                          updates[tables.deals + value[0]] = obj;
                          update(ref(rdb), updates);
                        }}
                      >
                        <option disabled value="DEFAULT">
                          {getLocalText("Phase.chooseHere")}
                        </option>
                        <option value=""></option>
                        <option value={constant.row1}>{constant.row1}</option>
                        <option value={constant.row2}>{constant.row2}</option>
                        <option value={constant.complete}>
                          {constant.complete}
                        </option>
                        <option value={constant.nonDisponible}>
                          {constant.nonDisponible}
                        </option>
                        <option value={constant.disponible}>
                          {constant.disponible}
                        </option>
                      </Form.Select>
                    </td>
                    <td className="text-base text-end">
                      <a
                        href={() => false}
                        onClick={() => props.handleEdit(value, index, value[0])}
                        className="text-blue cursor-pointer"
                      >
                        <FiEdit />
                      </a>
                      <a
                        href={() => false}
                        onClick={() =>
                          props.handleDelete(value, index, value[0])
                        }
                      >
                        <VscTrash className="mx-lg-5 mx-md-5 mx-2 text-pink cursor-pointer" />
                      </a>
                      <a
                        href={() => false}
                        className="pe-3 pe-md-2 pe-lg-0 text-blue cursor-pointer"
                        onClick={() =>
                          navigate("/associations", {
                            state: { item: value[1], key: value[0] },
                          })
                        }
                      >
                        <BsArrowRight />
                      </a>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  );
}
export default PhaseTable;

// const [data, setData] = useState([
//   {
//     name: "A-44",
//     squareFeet: "35115,11",
//     pricePerSquareFeet: "1,50$",
//     infrastructureCost: "10.000,00$",
//   },
//   {
//     name: "A-44",
//     squareFeet: "35115,11",
//     pricePerSquareFeet: "1,50$",
//     infrastructureCost: "10.000,00$",
//   },
//   {
//     name: "A-44",
//     squareFeet: "35115,11",
//     pricePerSquareFeet: "1,50$",
//     infrastructureCost: "10.000,00$",
//   },
//   {
//     name: "A-44",
//     squareFeet: "35115,11",
//     pricePerSquareFeet: "1,50$",
//     infrastructureCost: "10.000,00$",
//   },
//   {
//     name: "A-44",
//     squareFeet: "35115,11",
//     pricePerSquareFeet: "1,50$",
//     infrastructureCost: "10.000,00$",
//   },
// ]);
